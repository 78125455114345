<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage entrance exam</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Entrance exam
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn
                    @click="createEntranceExam()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon
                    >&nbsp; Add entrance exam
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="2" :md="search.date_type == 'custom' ? 2 : 3">
                  <v-text-field
                    class="form-control"
                    outlined
                    clearable
                    v-model="search.title"
                    dense
                    v-on:keyup.enter="searchEntrances()"
                    label="Title"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="dateFilters"
                    v-model="search.date_type"
                    outlined
                    dense
                    item-value="value"
                    item-text="title"
                    v-on:keyup.enter="searchEntrances()"
                    clearable
                    label="Date"
                  ></v-select>
                </v-col>
                
                <v-col cols="12" sm="12" md="2" v-if="search.date_type == 'custom'">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-on:keyup.enter="searchEntrances()"
                        v-model="search.scheduled_for"
                        v-bind="attrs"
                        clearable
                        v-on="on"
                        label=" Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="search.scheduled_for"
                      header-color="primary"
                      clearable
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.search.scheduled_for.$error"
                    >Please select a date</span
                  >
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="academicYears"
                    v-model="search.academic_year_id"
                    outlined
                    dense
                    item-value="id"
                    item-text="title"
                    v-on:keyup.enter="searchEntrances()"
                    clearable
                    label="Academic year"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-select
                    :items="statuses"
                    v-model="search.status"
                    outlined
                    dense
                    item-value="value"
                    item-text="title"
                    v-on:keyup.enter="searchEntrances()"
                    clearable
                    label="Status"
                  ></v-select>
                </v-col>

                <v-col cols="2" :md="search.date_type == 'custom' ? 2 : 2">
                  <v-btn
                    @click.prevent="searchEntrances()"
                    class="btn btn-primary btn-block w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class=""  style="min-height: 100vh">
              <v-skeleton-loader type="table-thead" v-if="isBusy">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isBusy" type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!isBusy">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">Exam info</th>
                    <th class="wrap-column">Program</th>
                    <th class="wrap-column">Date</th>
                    <th class="wrap-column">More info</th>
                    <th class="wrap-column px-3">Action</th>
                  </tr>
                </thead>
                <template>
                  <tr
                    v-show="entrances.length > 0"
                    v-for="(item, index) of entrances"
                    :key="index"
                  >
                    <td class="px-3 py-4 wrap-column">
                      <a @click="openQuickView(item)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.title }} </a>&nbsp;&nbsp;  <i class="fas fa-circle"
                                                              :class="item.is_active ? 'dot-active' : 'dot-inactive'"></i> <span class="count_number_badge">{{ item.total_verified_candidates || 0 }}</span>
                     <br/>
                        <span v-if="item.is_confirmed"><i class="fa fa-clock text-green mr-2" title="Confirmed exam"></i></span>
                        <span v-else><i class="fa fa-clock text-red mr-2" title="Confirmed exam"></i></span>
                        <span v-if="item.is_conducted"><i class="fa fa-check-circle text-green mr-2" title="Exam conducted"></i></span>
                        <span v-else><i class="fa fa-close text-red mr-2" title="Exam pending"></i></span>

                    </td>
                      <td>
                          <div class="mt-2 text-secondary">
                            <span class="font-weight-medium">
                              {{ item.setting && item.setting.display_text ? item.setting.display_text : "NA" }}
                            </span>
                          </div>
                      </td>
                    
                    <td class="px-3 py-4 wrap-column">
                      <div class="text-secondary">
                       <span class="font-weight-medium">{{item.entrance_scheduled_for ? item.entrance_scheduled_for : "NA" }}</span>
                      </div>
                      <div class="text-secondary">
                        <span class="font-weight-medium">
                          {{item.formatted_start_time ? item.formatted_start_time : "NA" }} to
                        {{
                            item.formatted_end_time
                                ? item.formatted_end_time
                                : "NA"
                          }}
                        </span>
                      </div>
                    </td>
                    
                    <td class="px-3 py-4 wrap-column">
<!--                      <div>-->
<!--                        <span-->
<!--                          class="badge badge-success text-lg`"-->
<!--                          v-bind:class="{-->
<!--                            'badge-success': item.is_confirmed,-->
<!--                            'badge-warning': !item.is_confirmed,-->
<!--                          }"-->
<!--                          >{{ item.is_confirmed ? "Scheduled" : "Not scheduled" }}</span-->
<!--                        >-->
<!--                      </div>-->
<!--                      <div class="mt-2">-->
<!--                        <span-->
<!--                          class="badge text-lg`"-->
<!--                          v-bind:class="{-->
<!--                            'badge-success':! item.is_expired,-->
<!--                            'badge-warning': item.is_expired,-->
<!--                          }"-->
<!--                          >{{ item.is_expired ? "Past" : "Upcoming" }}</span-->
<!--                        >-->
<!--                      </div>-->
                        <div class="mt-2">
                        <span
                                class="badge text-lg`"
                                v-bind:class="{
                            'badge-success': item.is_exam_notified,
                            'badge-danger': !item.is_exam_notified,
                          }"
                        >
                          {{ item.is_exam_notified ? "Scheduled confirmation notified" : "Scheduled confirmation not notified" }}
                        </span>
                        </div>
                      <div class="mt-2">
                        <span
                          class="badge text-lg`"
                          v-bind:class="{
                            'badge-success': item.result_published,
                            'badge-danger': !item.result_published,
                          }"
                          >{{ item.result_published ? "Result published" : "Result not published" }}</span
                        >
                      </div>

                      <div class="mt-2">
                        <span
                          class="badge text-lg`"
                          v-bind:class="{
                            'badge-success': item.is_result_notified,
                            'badge-danger': !item.is_result_notified,
                          }"
                        >
                          {{ item.is_result_notified ? "Result notified" : "Result not notified" }}
                        </span>
                      </div>



<!--                      <div class="mt-2">-->
<!--                        <span-->
<!--                          class="badge text-lg`"-->
<!--                          v-bind:class="{-->
<!--                            'badge-success': item.is_conducted,-->
<!--                            'badge-danger': !item.is_conducted,-->
<!--                          }"-->
<!--                        >-->
<!--                          {{ item.is_conducted ? "Conducted" : "Not conducted" }}-->
<!--                        </span>-->
<!--                      </div>-->
                    </td>
                    
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div"
                                    class="navi-item"
                                    v-if="item.is_active && checkIsAccessible('entrance-exam','view-candidates')">
                            <span href="#"
                                    @click="manageCandidate(item)" class="navi-link">
                              <span class="navi-icon">
                                <i class="fa fa-users"></i>
                              </span>
                              <span class="navi-text"> View candidates</span>
                            </span>
                            </b-dropdown-text>
                            <b-dropdown-text v-if="checkIsAccessible('entrance-exam', 'view-candidate-timetable')"
                                    tag="div" class="navi-item">
                            <span
                                    href="#"
                                    @click="previewCandidates(item.id)"
                                    class="navi-link"
                            >
                              <span class="navi-icon">
                                <i class="fa fa-file-pdf-o"></i>
                              </span>
                              <span class="navi-text">Candidate timetable</span>
                            </span>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"
                              v-if="checkIsAccessible('entrance-exam', 'notify-exam') && item.is_active && item.total_verified_candidates > 0 && item.is_confirmed">
                              <a href="#" @click="notifyExam(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-envelope-open"></i>
                                </span>
                                <span class="navi-text"> Notify exam confirmation via email</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'exam-conducted') && item.is_active  && !item.is_conducted && item.is_confirmed">
                                  <a href="#" @click="markAsConducted(item.id,'conducted')" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-check-circle"></i>
                                </span>
                                      <span class="navi-text">Mark as conducted</span>
                                  </a>
                            </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'exam-conducted') && item.is_active  && item.is_conducted && item.is_confirmed && !item.result_published">
                                  <a href="#" @click="markAsConducted(item.id,'unconducted')" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-check-circle"></i>
                                </span>
                                      <span class="navi-text">Mark as unconducted</span>
                                  </a>
                              </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"
                              v-if="checkIsAccessible('entrance-exam', 'result-published') && item.is_active && !item.result_published && item.is_confirmed && item.is_conducted">
                              <a href="#" @click="markAsResultPublished(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-calculator"></i>
                                </span>
                                <span class="navi-text">
                                  Mark as result published
                                </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'notify-result') && item.is_active && item.is_confirmed && item.is_conducted && item.result_published">
                              <a
                                href="#"
                                @click="notifyResult(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fa fa-envelope-open"></i>
                                </span>
                                <span class="navi-text"> Notify result via email</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"
                              v-if="checkIsAccessible('entrance-exam', 'set-result') && item.is_active && item.is_confirmed && item.is_conducted">
                              <router-link
                                :to="{
                                  name: 'entrance-exam-set-result',
                                  params: { id: item.id },
                                }"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="fa fa-book-reader"></i>
                                </span>
                                <span class="navi-text"> Set result</span>
                              </router-link>
                            </b-dropdown-text>

                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('entrance-exam', 'exam-confirmed') && item.is_active"
                            >
                              <a
                                href="#"
                                @click="markAsConfirmed(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon" v-if="!item.is_confirmed">
                                  <i class="fa fa-check-circle-o"></i>
                                </span>
                                  <span class="navi-icon" v-if="item.is_confirmed">
                                  <i class="fas fa-close"></i>
                                </span>
                                <span class="navi-text">
                                  {{ !item.is_confirmed ? "Mark as confirmed" : "Mark as not confirmed" }}
                                </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"

                            >
                              <a
                                href="#"
                                class="navi-link"
                                @click="editEntrance(item.id)"
                              >
                                <span class="navi-icon">
                                  <i class="flaticon-edit"></i>
                                </span>
                                <span class="navi-text"> Edit </span>
                              </a>
                            </b-dropdown-text>

<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'download-excel-format')">-->
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'import-result-excel') && item.is_active && item.is_confirmed && item.is_conducted">
                              <a href="#" @click="openAndCloseImportResultDialog(item.id)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-export"></i>
                                                                    </span>
                                <span class="navi-text"> Import result from excel </span>

                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'download-result-excel') &&  item.is_active && item.is_confirmed && item.is_conducted">

                              <a href="#" @click="downloadImportCsv(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-file-excel"></i>
                                </span>
                                <span class="navi-text"> Download sample excel for result </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if=" item.total_candidates == 0">
                                <a
                                        href="#"
                                        class="navi-link"
                                        @click="deleteEntrance(item.id)"
                                >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                                    <span class="navi-text"> Delete</span>
                                </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="entrances.length == 0">
                    <td class="px-3 text-center" colspan="5">
                      <strong>
                        No data available to display.
                      </strong>

                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                @input="getEntrances"
                class="pull-right mt-7"
                v-model="page"
                v-if="entrances.length > 0"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>

          </div>
        </div>
        <v-dialog v-model="result_dialog" persistent max-width="600px">
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Import result
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon  @click="closeResultDialog">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="import_file"
                    type="file"
                    outlined dense :error="$v.import_file.$error"
                    label="Import user enrollment result"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ></v-file-input>
                  <span class="text-danger" v-if="$v.import_file.$error">
                    Please select a csv file to import result
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="closeResultDialog">Cancel</v-btn>
              <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                @click.prevent="importResult"
                >Import result</v-btn
              >

            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="quickViewDialog" width="600px">
          <v-card v-if="entranceExamDetail">
            <v-toolbar dark>
              <v-card-title class="headline">
                {{ entranceExamDetail.title ? entranceExamDetail.title : "" }}
                <hr />
              </v-card-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeQuickViewDialog()">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="card-body" >
              <div>
                <div class="row">
                  <div class="float-left pl-12 mb-3">
                    <div class="font-weight-regular mb-2">
                      <strong> Type: </strong>
                      {{ entranceExamDetail.type | format_status }}
                    </div>
                    <div class="font-weight-regular mb-2">
                      <strong>Exam conducted date: </strong
                      >{{
                        entranceExamDetail.entrance_conducted_date
                          ? entranceExamDetail.entrance_conducted_date
                          : " N/A "
                      }}
                    </div>
                    <div class="font-weight-regular mb-2">
                      <strong> Exam conducted:</strong>
                      <span
                        class="badge badge-success text-lg`"
                        v-bind:class="{
                          'badge-success': entranceExamDetail.is_conducted,
                          'badge-danger': !entranceExamDetail.is_conducted,
                        }"
                        >{{
                          entranceExamDetail.is_conducted ? "Yes" : "No"
                        }}</span
                      >
                    </div>
                    <div class="font-weight-regular mb-2">
                      <strong> Result published:</strong>
                      <span
                        class="badge badge-success text-lg`"
                        v-bind:class="{
                          'badge-success': entranceExamDetail.result_published,
                          'badge-danger': !entranceExamDetail.result_published,
                        }"
                        >{{
                          entranceExamDetail.result_published ? "Yes" : "No"
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="float-right pl-12 mb-3">
                    <div class="font-weight-regular mb-2">
                      <strong> Result published date: </strong
                      >{{
                        entranceExamDetail.entrance_result_published_date
                          ? entranceExamDetail.entrance_result_published_date
                          : " N/A "
                      }}
                    </div>
                    <div class="font-weight-regular mb-2">
                      <strong>Exam notified date: </strong
                      >{{
                        entranceExamDetail.entrance_exam_notified_date
                          ? entranceExamDetail.entrance_exam_notified_date
                          : " N/A "
                      }}
                    </div>
                    <div class="font-weight-regular mb-2">
                      <strong> Exam notified:</strong>
                      <span
                        class="badge badge-success text-lg wrap-column"
                        v-bind:class="{
                          'badge-success': entranceExamDetail.is_exam_notified,
                          'badge-danger': !entranceExamDetail.is_exam_notified,
                        }"
                        >{{
                          entranceExamDetail.is_exam_notified ? "Yes" : "No"
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <v-btn
                      class="btn btn-standard cancel-btn"
                      depressed
                      @click="closeQuickViewDialog"
                      >Cancel
                    </v-btn>
                    <v-btn
                      @click="editEntrance(entranceExamDetail.id)"
                      class="text-white ml-2 btn btn-primary"
                      depressed
                      large
                    >
                      Edit
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>
<script>
import EntranceExamService from "@/core/services/entrance-exam/EntranceExamService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import { required, requiredIf } from "vuelidate/lib/validators";
import {API_URL} from "@/core/config";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const siteSetting=new SiteSettingService();
const entranceService = new EntranceExamService();
const enrollmentService = new UserEnrollmentService();
const academicYear = new AcademicYearService();

export default {
  name: "Fees",
  validations: {
    import_file: { required },
    search:{
      scheduled_for:{
        required:requiredIf(function(nestedModel){
          return (this.search.date_type == 'custom')
        })
      }
    }
  },
  data() {
    return {
      siteSetting:null,
      entrances: [],
      statuses: [
        { title: "Scheduled", value: "is_confirmed" },
        { title: "Not scheduled", value: "un_confirmed" },
        { title: "Exam notified", value: "exam_notified" },
        { title: "Result published", value: "result_published" },
        { title: "Result notified", value: "result_notified" },
        { title: "Active", value: "is_active" },
        { title: "Inactive", value: "in_active" },
      ],
      result_dialog: null,
      menu2: false,
      import_file: null,
      entranceId: null,
      page: null,
      total: null,
      perPage: null,
      expanded: [],
      entrance_types: [
        { title: "Current", value: "current" },
        { title: "Past", value: "past" },
      ],
      search: {
        title: "",
        scheduled_for: "",
        // entrance_type: "current",
        date_type: "today",
        status: "",
        academic_year_id: ""
      },
      singleExpand: false,
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Program/academic year", value: "display_text" },
        { text: "Schedule date", value: "entrance_scheduled_for" },
        { text: "Start time", value: "formatted_start_time" },
        { text: "End time", value: "formatted_end_time" },
        { text: "Total candidate", value: "total_candidates" },
        { text: "Is confirmed", value: "is_confirmed" },
        { text: "Is expired", value: "is_manage_candidate_expired" },
        { text: "Result published", value: "result_published" },
        { text: "Result notified", value: "is_result_notified" },
        { text: "Exam notified", value: "is_exam_notified" },
        { text: "Exam conducted", value: "is_conducted" },
        { text: "Action", value: "action" },
        { text: "", value: "data-table-expand" },
      ],
      isBusy: false,
      quickViewDialog: false,
      desserts: [],
      entranceExamDetail: null,
      dateFilters:[
        {
          title: 'Upcoming',
          value: 'upcoming'
        },
        {
          title: 'Past',
          value: 'past'
        },
        {
          title: 'Custom',
          value: 'custom'
        },
      ],
      academicYears: [],
    };
  },
  mounted() {
    // this.search.scheduled_for = this.$moment().format("YYYY-MM-DD");
    this.getSetting();
    this.getAcademicYears();



  },
  methods: {
    searchEntrances(){
      this.$v.search.$touch();
      if (this.$v.search.$error) {
        setTimeout(() => {
          this.$v.search.$reset();
        }, 3000);
      } else {
        this.page = 1;
        this.getEntrances();
      }
    },
    createEntranceExam() {
      this.$router.push({ name: "entrance-exam-new" });
    },
    getSetting() {
      siteSetting.show().then(response => {
        this.siteSetting = response.data;
          if(this.siteSetting && this.siteSetting.enrollment_year_id){
              this.search.academic_year_id=this.siteSetting.enrollment_year_id;
          }else{
              this.search.academic_year_id=null;
          }
        this.getEntrances();
      });
    },
    getEntrances() {

      this.isBusy = true;
      entranceService
        .paginate(this.search, this.page)
        .then((response) => {
          this.entrances = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    editEntrance(id) {
      this.$router.push({ name: "entrance-exam-update", params: { id: id } });
    },
    deleteEntrance(id) {
      this.$confirm({
        message: `Are you sure you want to delete this exam?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService.delete(id).then((response) => {
              this.$snotify.success("Exam deleted");
              this.getEntrances();
            });
          }
        },
      });
    },
    markAsConducted(id,type) {
        let message=`Are you sure you wan to mark the exam status as ${type =='conducted' ? 'conducted' :'unconducted'}`
      this.$confirm({
        message: message,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService
              .examConducted(id)
              .then((response) => {
                this.$snotify.success("Exam status changed");
                this.getEntrances();
              })
              .catch((err) => {
                this.$snotify.error(
                  "Something went wrong. Please try again later!!"
                );
              });
          }
        },
      });
    },
    markAsResultPublished(id) {
      this.$confirm({
        message: `Are you sure you want to set result status to publish?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService
              .resultPublished(id)
              .then((response) => {
                this.$snotify.success("Result published");
                this.getEntrances();
              })
              .catch((err) => {
                this.$snotify.error(
                  "Something went wrong. Please try again later!!"
                );
              });
          }
        },
      });
    },
    markAsConfirmed(id) {
      this.$confirm({
        message: `Do you want to change confirmation status for this exam?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService
              .resultConfirmed(id)
              .then((response) => {
                this.$snotify.success("Status changed");
                this.getEntrances();
              })
              .catch((err) => {
                this.$snotify.error(
                  "Something went wrong. Please try again later!!"
                );
              });
          }
        },
      });
    },
    previewCandidates(entranceId){
        entranceService
            .previewAllCandidatesPDF(entranceId)
    },
    notifyExam(id) {
      this.$confirm({
        message: `Do you wish to continue notifying all applications about their entrance exam via email?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService
              .notifyExam(id)
              .then((response) => {
                this.$snotify.success("Candidates notified");
                this.getEntrances();
              })
              .catch((err) => {
                this.$snotify.error(
                  "Something went wrong. Please try again later!!"
                );
              });
          }
        },
      });
    },
    notifyResult(id) {
      this.$confirm({
        message: `Are you sure you want to notify this exam result?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            entranceService
              .notifyResult(id)
              .then((response) => {
                this.$snotify.success("Entrance exam deleted");
                this.getEntrances();
              })
              .catch((err) => {
                this.$snotify.error(
                  "Something went wrong. Please try again later!!"
                );
              });
          }
        },
      });
    },
    manageCandidate(item) {
      this.$router.push({name: 'entrance-exam-manage-candidate', params: {id: item.id}});
    },
    importResult() {
      this.$v.import_file.$touch();
      if (this.$v.import_file.$error) {
        setTimeout(() => {
          this.$v.import_file.$reset();
        }, 3000);
      } else {
        this.$bus.emit("toggleLoader");
        let fd = new FormData();
        fd.append("file", this.import_file);
        fd.append("entrance_id", this.entranceId);
        enrollmentService
          .importResult(fd)
          .then((response) => {
            this.$bus.emit("toggleLoader");
            this.result_dialog = false;
            this.import_file = null;
            this.$v.import_file.$reset();
            this.$snotify.success("Information imported");
            this.entranceId=null;
          })
          .catch((error) => {
            this.$bus.emit("toggleLoader");
            this.$snotify.error("Something went wrong. Please try again later");
          });
      }
    },
    openAndCloseImportResultDialog(entranceExamId) {
      this.import_file = null;
      this.entranceId=entranceExamId;
      if (!this.result_dialog) {
        this.result_dialog = true;
      } else {
        this.result_dialog = true;
      }
      this.$v.import_file.$reset();
    },
    closeResultDialog(){
      this.$v.import_file.$reset();
      this.entranceId=null;
      this.result_dialog = false;
    },
    resetSearch() {
      this.search = {
        title: "",
        scheduled_for: "",
        // entrance_type: "current",
        date_type: "today",
        status: "",
        academic_year_id: ""
      };
      this.getEntrances();
    },
    openQuickView(item) {
      this.quickViewDialog = true;
      this.entranceExamDetail = item;
    },
    closeQuickViewDialog() {
      this.quickViewDialog = false;
      this.entranceExamDetail = null;
    },
    getAcademicYears(){
      academicYear
      .all()
      .then((res) => {
          this.academicYears = res.data;
      })
      .catch((err) => {

      });
    },

    downloadImportCsv(entranceId) {
      let url = "";
      let data = {entrance_id: entranceId}
      var queryString = Object.keys(data)
          .map(key => {
            return (encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
          }).join("&");
      if (queryString) {
        url = url + "?" + queryString;
      }
      url = API_URL + 'user/download/import/entrance-exam/result' + url;
      window.open(
          url,
          "_blank"
      );
    },
  },
};
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>